import React, { useEffect, useRef } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import classes from "../components/single.module.css"
import Title from "../components/title/subtitle"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const IllustrationTemplate = ({ data }) => {
  const revealRefs = useRef([])
  revealRefs.current = []
  const addToRefs = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    var tl = gsap.timeline()
    tl.fromTo(
      ".post-content-header",
      { y: 20, opacity: 0 },
      {
        opacity: 1,
        y: 0,
      }
    )
    tl.fromTo(
      ".post-content-body",
      { y: 20, opacity: 0 },
      {
        opacity: 1,
        y: 0,
      }
    )

    revealRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.5,
          autoAlpha: 1,
          ease: "none",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            /* start: 'top center+=100', */

            toggleActions: "play none none none",
          },
        }
      )
    })
  }, [])

  /*   useEffect(() => {
    ScrollTrigger.batch("figure", {
      start: "top center+=100",
      end: "top center",
      markers:true,
      onEnter: batch =>
        gsap
          .timeline({})
          .fromTo(
            ".post-content-header",
            { y: 20, opacity: 0 },
            {
              opacity: 1,
              y: 0,
            }
          )
          .fromTo(
            ".post-content-body",
            { y: 20, opacity: 0 },
            {
              opacity: 1,
              y: 0,
            }
          )
          .to(batch, {
            y: -20,
            opacity: 1,
          }),
    })
  }, [])
 */
  const post = data.contentfulIllustration
  return (
    <Layout>
      <SEO
        title={post.title}
        description={`しょうのまきの制作事例${post.title}のご紹介です。東京でイラストデザイン制作やロゴデザイン、チラシ制作、フライヤー制作、パンフレットの制作、カード類の制作などの広告印刷物の制作などおこなっております。`}
        keywords={[
          `イラスト制作`,
          `美容室の広告印刷物の制作`,
          `ロゴデザイン`,
          `チラシ制作`,
        ]}
      />

      <header className="post-content-header">
        {post.category ? (
          <p className={`${classes.category}`}>
            <Link to={`/illustration/category/${post.category.slug}`}>
              {post.category.name}
            </Link>{" "}
          </p>
        ) : null}

        <Title title={post.title} />
      </header>

      <article className={`postWrap ${classes.postWrap}`}>
        <div
          className={`post-content-body post-content-width ${classes.desc}`}
          dangerouslySetInnerHTML={{
            __html: post.body ? post.body.childContentfulRichText.html : null,
          }}
        />

        <p className="post-content-date">
          日付/{post.publishDate}
          <br></br>使用ツール/
          {post.tools
            ? post.tools.map((b, index) => (
                <span key={index}>
                  {b}
                  {index < post.tools.length - 1 ? ", " : null}
                </span>
              ))
            : null}
        </p>
        {post.image
          ? post.image.map((b, index) => (
              <figure
                key={index}
                className="kg-card kg-image-card kg-width-full"
                ref={addToRefs}
              >
                {b ? (
                  <Img
                    className="kg-image"
                    fluid={b ? b.fluid : null}
                    alt={b.title}
                    placeholderStyle={{ backgroundColor: "rgb(248, 248, 248)" }}
                  />
                ) : null}
              </figure>
            ))
          : null}
      </article>
    </Layout>
  )
}
export default IllustrationTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulIllustration(slug: { eq: $slug }) {
      title
      category {
        name
        slug
      }
      slug
      publishDate(formatString: "YYYY年MM月")
      tools
      image {
        fluid(maxWidth: 1400, quality: 80) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childContentfulRichText {
          html
        }
      }
    }
  }
`

/* fluid(maxWidth: 2000, maxHeight: 1333, resizingBehavior: PAD) {
  ...GatsbyContentfulFluid_tracedSVG
} */

/* fluid(
  resizingBehavior: CROP
  maxWidth: 1400
  maxHeight: 910
  quality: 80
) */
